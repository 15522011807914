// import fetch from 'node-fetch'; 
  
// utility function - fetchProviderIdWithEntra
export const fetchProviderIdWithEntra = async (sub, accessToken) => {
    let apiEndpoint = `${process.env.REACT_APP_PROVIDER_BY_ENTRA_BASE_URL}`;
    const entraCodeUrl = `${apiEndpoint}${sub}`;
    
    const res = await fetch(entraCodeUrl, {
      method: 'GET',
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`,
      },
    });
  
    if (!res.ok) {
      throw new Error('Provider ID not found');
    }
    let pid = await res.text();
    return pid; // Get the provider ID
  };
  
  // utility function - fetchGroupId
  export const fetchGroupId = async (providerId, accessToken) => {
    let apiEndpoint = process.env.REACT_APP_PROVIDERS_API_BASE_URL;
    const providerControllerURL = `${apiEndpoint}${providerId}`;
  
    const res2 = await fetch(providerControllerURL, {
      method: 'GET',
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`,
      },
    });
  
    if (!res2.ok) {
      throw new Error('Failed to fetch group ID');
    }

    let pg = await res2.json();
    return { 
      name: pg.organization.organizationGroup.name, 
      id: pg.organization.organizationGroup.organizationGroupId 
    }; // Get the group map
  };

  // utility function - fetchGroupId
  export const fetchGroupPlans = async (groupId, accessToken) => {
    let apiEndpoint = process.env.REACT_APP_OUTCOME_SERVICE_BASE_URL;
    const outcomePlansURL = `${apiEndpoint}organization-group/${groupId}`;
    
    const res2 = await fetch(outcomePlansURL, {
      method: 'GET',
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    
    if (!res2.ok) {
      throw new Error('Failed to fetch group plans');
    }

    let planJson = await res2.json();
    if (!planJson) {
      throw new Error('No plans found');
    }
  
    let plans = planJson.outcomePlans;
    let planDetails = [];
    for (let i = 0; i < plans.length; i++) {
      planDetails.push({ name: plans[i].name, id: plans[i].outcomePlanId });
    }
    return planDetails; // return plan details
  };
  
  // utility function - submitFormData
  export const submitFormData = async (requestObject, accessToken) => {
    let apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
  
    const response = await fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(requestObject),
    });
  
    if (!response.ok) {
      throw new Error('Failed to submit form data');
    }
  
    const responseData = await response.json();
    alert('Case created with the number ' + responseData.id);
  };
  